import { useState, useEffect } from "react";
import {User} from "firebase/auth";

import isUserPremium from "../stripe/is-user-premium";

const usePremiumStatus = (user: User | undefined): boolean => {
    const [premiumStatus, setPremiumStatus] = useState<boolean>(false);
    useEffect(() => {
        if (user) {
            const checkPremiumStatus = async () => {
                setPremiumStatus(await isUserPremium());
            }

            checkPremiumStatus();
        }
    }, [user]);
    return premiumStatus;
}

export default usePremiumStatus;
